import React from "react";
import styled from "styled-components";
import { siteColors } from "../constants/colors";
import breakpoints from "../constants/breakpoints";

const MainContainer = styled.div`
width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   font-size: 1.2em;
`;

const BoxContainer = styled.div`
margin-top: 3em;
width: 720px;
max-width: 720px;
  background: ${ siteColors.darker };
   color:  ${ siteColors.light };
border: 0.15rem solid ${ siteColors.main };
border-radius: 0.15em;
  padding-top: 0.5em;
  padding-left: 2em;
  padding-right: 2em;
  @media (max-width: ${ breakpoints.half1080 }px) {
    max-width: 640px;
    max-width: 640px;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
    font-size: 1.0em;
    max-width: 480px;
    max-width: 480px;
  }

  @media (max-width: ${ breakpoints.xLargePhone }px) {
    font-size: 0.75em;
    max-width: 320px;
    max-width: 320px;
  }
`;

const BoxHeading=styled.div`
  font-size: 2em;
  @media (max-width: ${ breakpoints.iPad }px) {
font-size: 1.6em;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    font-size: 1.2em;
  }
`;

const SubHeading=styled.div`
  font-size: 1.4em;
  font-weight: 500;
  @media (max-width: ${ breakpoints.iPad }px) {
    font-size: 1.2em;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    font-size: 1.0em;
  }
`;

const BoxCopy = styled.div`
  width: 100%;
  padding-top: 1em;
`;

export { MainContainer, BoxContainer, BoxHeading, SubHeading, BoxCopy };
