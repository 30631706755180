import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { InternalLink, ExternalLink } from "../components/sitelinks";
import { AdaptiveHostedVideo } from "../components/hosted-video.js";
import {
    MainContainer,
    BoxContainer,
    BoxHeading,
    SubHeading,
    BoxCopy
} from "../components/simplepagelayout";



const DocumentationPage = () => (
    <Layout>
        <SEO title="Learn" />
        <MainContainer>

            <BoxContainer>
                <BoxHeading>
                Contents</BoxHeading>
                <BoxCopy>
                <div>
                    <ExternalLink href="#contourmanual">Contour Rig Tools Manual</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="#examplerigs">Example Rigs and Meshes</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="#introducingcontour">Introducing Contour Rig Tools</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="#riggingsimple">Rigging a Simple Character</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="#conventionaljoints">Using Contour to Drive Conventional Joints</ExternalLink>
                </div>
                <p>
                    <ExternalLink href="#thickobjects">Deforming Thick Objects</ExternalLink>
                </p>
                </BoxCopy>
            </BoxContainer>

            <BoxContainer id="contourmanual">
                <BoxHeading>
                    Contour Rig Tools Manual
                </BoxHeading>
                <BoxCopy>
                    <ul>
                        <li><ExternalLink href="https://manuals.notionalpipe.com/rigtools/">Contour Rig Tools Manual as multi-page HTML</ExternalLink></li>
                        <li><ExternalLink href="https://manuals.notionalpipe.com/rigtools-single-page/">Contour Rig Tools Manual as single-page HTML</ExternalLink></li>
                    </ul>

                </BoxCopy>

            </BoxContainer>

            <BoxContainer id="examplerigs">
                <BoxHeading>
                    Example Rigs and Meshes
                </BoxHeading>
                <BoxCopy>
                    <SubHeading>Contour Man Example Rig</SubHeading>
                    <p>Contour Man is an example rig that demonstrates the most important features of Contour Rig Tools, like flexible control over limb shapes, perfect twist behavior, and stretchy IK. The rig also includes a simple muscle system built with Contour, and uses Contour in concert with other Maya deformation tools such as Pose Interpolators, post-deformation blendshapes, and delta mush. It’s both a great way for animators to test out a Contour rig, and a learning tool for TDs who want to examine how one works internally.</p>

                    <p><ExternalLink href="https://downloads.notionalpipe.com/example_rigs/ContourMan_v1.zip">Download Contour Man Version 1</ExternalLink></p>

                    <p>
                        Take a guided tour of the Contour Man rig with Raf Anzovin:
                    </p>

                    <AdaptiveHostedVideo url="https://www.youtube.com/embed/vfcrBDdZJcA" title="Contour Man Guided Tour" widthProportion="1.0" />

                    <p>
                        To use the Contour Man Rig you'll need the Contour Rig Tools plugin. You can download Contour <InternalLink to="/software">here</InternalLink>. While the features required to make Contour rigs are limited to a 30-day trial, animating Contour rigs is free of charge, so you can continue to animate with Contour Man even after the trial expires.
                    </p>

                    <p>NOTE: Due to bugs present in some versions of Maya, Contour Man won't work properly with Animation Caching or GPU Override enabled in Maya 2018, 2019 and 2020. You can still try Contour Man in those versions of Maya, you’ll just need to turn off those features. Both issues were resolved in Maya 2022. It is possible to build Contour Rigs that work with these features enabled in Maya versions prior to 2022, but it requires a bit of extra rigging, and we wanted to keep this example rig as clean as possible.</p>

                    <SubHeading>Contour Man Mesh</SubHeading>
                    <p>If you'd like to follow along with the <ExternalLink href="https://manuals.notionalpipe.com/rigtools/03-getting-started/" target="_blank">"Getting Started"</ExternalLink> chapter of the manual, or you want to experiment with rigging Contour Man yourself, you can download the Contour Man mesh <ExternalLink href="https://downloads.notionalpipe.com/example_rigs/ContourMan_mesh_only_v1.ma">here</ExternalLink>.</p>


                </BoxCopy>

            </BoxContainer>

            <BoxContainer id="introducingcontour">
                <BoxHeading>
                    Introducing Contour Rig Tools
                </BoxHeading>
                <BoxCopy>
                    <p>Each video from this series focuses on one of Contour Rig Tool's features.</p>
                    <AdaptiveHostedVideo url="https://www.youtube.com/embed/videoseries?list=PL_vQGrJsKbWbpmUrsgh1sEnFK0HwH01YA" title="Introducing Contour Rig Tools" widthProportion="1.0" />

                </BoxCopy>

            </BoxContainer>

            <BoxContainer id="riggingsimple">
                <BoxHeading>
                    Rigging a Simple Character
                </BoxHeading>
                <BoxCopy>
                    <p>In this stream, we rig a simple character entirely from scratch.</p>
                    <AdaptiveHostedVideo url="https://www.youtube.com/embed/cLTwnZrAIGw" title="Rigging a Simple Character" widthProportion="1.0" />

                    <p>Download the <ExternalLink href="https://downloads.notionalpipe.com/example_meshes/thing_mesh.zip">alien rabbit mesh</ExternalLink> to follow along.</p>

                </BoxCopy>

            </BoxContainer>

            <BoxContainer id="conventionaljoints">
                <BoxHeading>
                    Using Contour to Drive Conventional Joints
                </BoxHeading>
                <BoxCopy>
                    <p>Sometimes using Contour's spline-based deformation isn't an option, such as when exporting to a game engine, and you need to rely on conventional Maya joints and linear blend weighting. Contour can drive Maya joints, giving you a lot of the advantages of Contour deformation in a form that can be exported to a game engine.</p>
                    <AdaptiveHostedVideo url="https://www.youtube.com/embed/LUbRICxJcRM" title="Using Contour to drive conventional Maya joints" widthProportion="1.0" />

                </BoxCopy>

            </BoxContainer>

            <BoxContainer id="thickobjects">
                <BoxHeading>
                    Deforming Thick Objects
                </BoxHeading>
                <BoxCopy>
                    <p>Good deformation of thick objects, like this pirate's torso, is very difficult with conventional rigging techniques. That's another place where Contour's spline-based deformation tools shine, whether used to deform the mesh or to drive conventional joints for game engine export.</p>
                    <AdaptiveHostedVideo url="https://www.youtube.com/embed/T5Ocy-qhog4" title="Deforming thick objects" widthProportion="1.0" />

                </BoxCopy>

            </BoxContainer>

            {/*
            <BoxContainer>
                <BoxHeading>
                    Notional Pipe Live Streams
                </BoxHeading>
                <BoxCopy>
                    <p>We live stream every Tuesday at 4pm EST. Here's a playlist of our past streams.</p>
                    <AdaptiveHostedVideo url="https://www.youtube.com/embed/videoseries?list=PL_vQGrJsKbWZTykmIiyGVC8JH3wDqkDfE" title="Notional Pipe Live Streams" widthProportion="1.0" />

                    <p>To get notified when we stream next, join our <ExternalLink href="https://www.youtube.com/channel/UCoTin4y_JsdGbK2imeiGTQA" target="_blank">Youtube channel</ExternalLink>.</p>

                </BoxCopy>

            </BoxContainer>
            */}

        </MainContainer>
    </Layout>
);

export default DocumentationPage;
